
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

// local utils
import { googleLogin, facebookLogin, twitterLogin } from "../actions/auth"

// ** add logic to redirect user to connect wallet page only if user initiated the request *


const Login = ({ isAuthenticated, publicKey, googleLogin, facebookLogin }) => {


  let history = useHistory();

  const [fromHome, setFromHome] = useState(false);
  useEffect( () => {
    setFromHome(history.location.state);

  }, [history])


  // google login
  const success = e => {
    try {
      googleLogin(e.accessToken);
    }
    catch (err) {
      console.log(err);
    }
  }

  const failure = e => {
    console.log('failed to log with google!');
  }

  // facebook login
  const responseFacebook = (e) => {
    try {
      facebookLogin(e.accessToken);

    } catch (err) {
      console.log(err);
    }
  }

  if (isAuthenticated) {
    if (fromHome) {
      return <Redirect to='/connect-wallet' />

    } else if (history.length > 3) {
      history.go(-1);
    } else {
      return <Redirect to='/' />
    }
  }


  return (
    <div>
    <div className="container">
        <div className='col' style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
        }}>
          <div className='row' style={{marginRight: '10px'}}>
            <GoogleLogin
              clientId='567955079853-r761tjg35ngl6ac7lmo7p5uiebu4hif0.apps.googleusercontent.com'
              buttonText="LOGIN WITH GOOGLE"
              onSuccess={success}
              onFailure={failure}
            />
          </div>
          <div className='row'>
            <FacebookLogin
              appId="266982812047378"
              autoLoad={false}
              fields="name,email"
              callback={responseFacebook}
              icon="fa-facebook"
            />
          </div>
        </div>
    </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    publicKey: state.auth.publicKey,
});

export default connect(mapStateToProps, { googleLogin, facebookLogin }) (Login);