
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress'
import { Alert, AlertTitle, Grid, List, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import { CSVLink } from "react-csv";
import { FacebookShareButton } from 'react-share';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Snackbar from '@mui/material/Snackbar';
import RefreshIcon from '@mui/icons-material/Refresh';
import _debounce from 'lodash.debounce';
import {
    Link,
    useRouteMatch,
    useLocation
} from "react-router-dom";

// local utils
import { ServerErrorMessage } from './PageNotFound';
import { loadPublicList } from '../actions/publicList';
import LineChart from '../charts/LeaderBoardChart';
import { useWindowDimensions } from './Utils';


// snackbar rendering issue not fixed


const LeaderBoard = ({ publicWatchlist, freshLoad, navbarSize, loadPublicList }) => {

    // console.log(publicWatchlist)

    let queryName = useQuery().get('name');
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [listCount, setListCount] = useState(publicWatchlist.length);
    const [activeButton, setActiveButton] = useState(0);
    const [tweetMessage, setTweetMessage] = useState('');
    const [csvData, setCsvData] = useState('');
    const [rows, setRows] = useState([]);
    
    // active list info
    const [openList, setOpenList] = useState(null);
    const [openlistName, setOpenlistName] = useState('');
    const [openlistSymbols, setOpenlistSymbols] = useState([]);
    const [openlistDates, setOpenlistDates] = useState([]);

    const handleInitSet = () => {
        if (publicWatchlist.length > 0) {
            let al = publicWatchlist[0]
            setOpenList(al);
            setOpenlistName(al.Name);
            setOpenlistSymbols(al.Results.map(v => v.Symbol));
            setOpenlistDates(al.Results.map(v => v.SavedDate));
        }
    };
    useEffect( () => { handleInitSet() }, [publicWatchlist]);


    useEffect(() => {
        if (!freshLoad) {
            loadPublicList();
        };
        if (publicWatchlist.length === 0) {
            setLoading(true);
        };
        handleQueryName();
    }, []);


    useEffect(() => {
        if (publicWatchlist.length > 0) {
            setServerError(false);
            setLoading(false);
            handleInitSet();
        } else {
            setLoading(true);
        };
        setListCount(publicWatchlist.length);
        handleQueryName();

    }, [publicWatchlist]);


    const handleTableRows = (al) => {

        if (al !== null && al.Results) {
            setRows(
                al.Results.map( (item, index) => {
                    return {
                        id: index,
                        symbol: item.Symbol,
                        init_price: item.SavedPrice,
                        curr_price: item.CurrentPrice,
                        change: item.Change,
                        percent_change: item.PercentChange,
                        init_date: item.SavedDate,
                    }
                })
            );    
        }
    };
    // useEffect( () => { handleTableRows()}, []);

    useEffect( () => { 
        handleTableRows(openList);
        handleCsvData(openList);
        handleTweetMessage(openList);
    }, [openList])


    const handleRefresh = () => {
        window.location.reload();
    }


    const handleQueryName = () => {
        if (queryName !== null) {

            let idx = publicWatchlist.findIndex( v => v.Name === queryName);
            // console.log(idx);
            if (publicWatchlist[idx] && publicWatchlist[idx].Name === queryName) {
                let al = publicWatchlist[idx]
                setOpenList(al);
                setOpenlistName(al.Name);
                setOpenlistSymbols(al.Results.map(item => item.Symbol));
                setOpenlistDates(al.Results.map(item => item.SavedDate));
                setActiveButton(idx);
            }
        }
    };

    // console.log(openList ? openList.Name : 'no list name');
    // console.log(csvData);
    // console.log(queryName);

    const cardColor = '#f5f5f5';
    
    const columns = [
        { field: 'symbol', headerName: 'Symbol', width: 100 },
        { field: 'init_price', headerName: 'Initial Price', width: 100 },
        { field: 'curr_price', headerName: 'Current Price', width: 100 },
        { field: 'change', headerName: 'Change $', width: 100 },
        { field: 'percent_change', headerName: '% Change', width: 100 },
        { field: 'init_date', headerName: 'Initial Date', width: 150 },
    ];


    const handleListItemClick = (name, idx) => {
        // console.log(name, idx)
        let al = publicWatchlist[idx]

        setOpenList(al);
        setOpenlistName(al.Name);
        setOpenlistSymbols(al.Results.map(item => item.Symbol));
        setOpenlistDates(al.Results.map(item => item.SavedDate));
        handleTweetMessage(al);
        handleCsvData(al);
        setActiveButton(idx);
    };

    
    const handleKeyPress = (e) => {
        
        if (e.keyCode === 40) {
            if (activeButton+1 < listCount) {
                setActiveButton(prev => prev+1)
            } else {
                setActiveButton(0)
            }
        } else if (e.keyCode === 38) {
            if (activeButton > 0) {
                setActiveButton(prev => prev-1)
            } else {
                setActiveButton(listCount-1)
            }
        };
        
    };    
    
    // copy chart to clipboard
    // fix rendering issue with Snack actions
    const [openSnack, setOpenSnack] = useState(false);
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const handleChartCopy = () => {
        // console.log('copy chart');
        if (chartRef.current !== null) {
            var canvas = chartRef.current.ctx.canvas;

            try {
                canvas.toBlob(blob => navigator.clipboard.write([new window.ClipboardItem({'image/png': blob})]));
                // setOpenSnack(true);
            } catch (error) {
                connect.error(error)
            }    
        }
    };

    const handleCsvData = (al) => {
        if (al && al.Results) {
            setCsvData(al.Results.map(
                (t) => ({Symbol: t.Symbol, InitialPrice: t.SavedPrice, CurrentPrice: t.CurrentPrice, Change: t.Change, InitialDate: t.SavedDate})
            ))
        } else {
            setCsvData('')
        }
    };

    const handleTweetMessage = (al) => {
        if (al && al.Results) {
            var listItems = '$' + al.Results.map(t => t.Symbol).join(', $');
            var tweetMessage = 'Published: ' + al.PublishedDate+ '   Return: $' + al.TotalChange +'      '+ listItems;
            setTweetMessage(tweetMessage);
        
        } else {
            setTweetMessage('');
        }    
    }



    // setting table dimensions *** potential to improve ***
    const chartRef = useRef(null);
    const mainBoxHeight = useWindowDimensions().height - navbarSize - 15;


    return (
    <div> { loading ?
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <CircularProgress />
        </div> : serverError ?
        <div>
            <ServerErrorMessage />
        </div>:
        <Grid container style={{height: mainBoxHeight, marginTop: '10px'}}>
            <Grid  item xs={2.5} style={{backgroundColor: cardColor, overflow: 'auto', height: mainBoxHeight}}>
                <LeftPanel
                    publicList={publicWatchlist}
                    activeButton={activeButton}
                    cardColor={cardColor}
                    handleListItemClick={handleListItemClick}
                    panelHeight={mainBoxHeight}
                    handleKeyPress={handleKeyPress}
                ></LeftPanel>
            </Grid>

            <Grid item xs={9}  style={{paddingLeft: '1rem', paddingRight: '1rem', height: mainBoxHeight}}>
                { publicWatchlist.map( v => v.Name).includes(queryName) || queryName === null ?
                <MainPanel
                    openlistName = {openlistName}
                    openlistSymbols={openlistSymbols}
                    openlistDates={openlistDates}
                    rows={rows}
                    columns={columns}
                    cardColor={cardColor}
                    mainBoxHeight={mainBoxHeight}
                    chartRef = {chartRef}
                ></MainPanel>:
                <NoMatch name={queryName}></NoMatch>
                }
            </Grid>

            <Grid item xs={.5}  style={{background: cardColor, height: mainBoxHeight}}>
                <RightPanel
                    tweetMessage={tweetMessage}
                    csvData={csvData}
                    activelistName={openlistName}
                    handleChartCopy={handleChartCopy}
                    handleRefresh={handleRefresh}
                ></RightPanel>
            </Grid>
        </Grid>
        }

        <div>
            <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={openSnack}
            autoHideDuration={3000}
            onClose={handleSnackClose}
            message="Image copied to clipboard"
            />
        </div>
    </div>
    )
}

const mapStateToProps = (state) => ({
    publicWatchlist: state.publicList.publicList,
    freshLoad: state.publicList.isFresh,
    navbarSize: state.barSize.navbarSize,
});

export default connect( mapStateToProps, { loadPublicList } ) (LeaderBoard);


function NoMatch({name}) {  
    return (
        <Alert severity='error' size='large'>
            <AlertTitle>watchlist not found!</AlertTitle>
            Public watchlist <span style={{color: 'blue', fontWeight: 'bold'}}>{name}</span> does not exists. 
            Select a watchlist from the menu.
        </Alert>
    );
}

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LeftPanel = ({
    publicList,
    activeButton,
    cardColor,
    handleListItemClick,
    panelHeight,
    handleKeyPress
}) => {

    let { path, url } = useRouteMatch();

    return (
        <div style={{height: panelHeight, outline: 'none'}} tabIndex='-1'>
            <List dense={true}>
                {publicList.map( (v, idx) => (
                    <Fragment key={idx}>
                    <Link to={`${path}/list?name=${v.Name}`} style={{textDecoration: 'none'}}>
                    <ListItemButton key={idx}
                    onClick={(e) => handleListItemClick(v.Name, idx)}
                    sx={{backgroundColor: idx === activeButton ? '#bbd9fb' : cardColor}}
                    >
                        <div style={{paddingLeft: '10px'}} key={`div-${idx}`}>
                            <h6 key={`h1-${idx}`}>Name: { v.Name.toUpperCase() }</h6>
                            <h6 key={`h2-${idx}`}>Date: { v.PublishedDate}</h6>
                            <h6 key={`h3-${idx}`}>Return: $ { v.TotalChange }</h6>
                        </div>
                    </ListItemButton>
                    </Link>
                    <hr />
                    </Fragment>
                ))}
            </List>
        </div>
    )
}


const RightPanel = ({
    tweetMessage,
    csvData,
    activelistName,
    handleChartCopy,
    handleRefresh,

}) => {
    return (
        <Stack>
            <IconButton
            className="tweet"
            href={`https://twitter.com/intent/tweet?text=${tweetMessage}`}
            target="_blank"
            rel="noopener noreferrer"
            >
            <Tooltip title="Share on Twitter">
                <TwitterIcon />
            </Tooltip>
            </IconButton>

            <IconButton>
            <FacebookShareButton url='https://watchlist101.com' quote={tweetMessage}>
            <Tooltip title="Share on Facebook">
                <FacebookIcon />
            </Tooltip>
            </FacebookShareButton>
            </IconButton>

            <IconButton>
            <CSVLink
            data={csvData}
            filename={activelistName + '.csv'}
            >
            <Tooltip title="Download the Watchlist">
                <FileDownloadOutlinedIcon sx={{color: '#495057'}} />
            </Tooltip>
            </CSVLink>
            </IconButton>

            <IconButton>
            <Tooltip title="Refresh List">
                <RefreshIcon onClick={() => handleRefresh()} />
            </Tooltip>
            </IconButton>

            <IconButton onClick={handleChartCopy}>
            <Tooltip title="Copy Chart">
            <ContentCopyOutlinedIcon/>
            </Tooltip>
            </IconButton>

        </Stack>
    )

}


const MainPanel = ({
    openlistName,
    openlistSymbols,
    openlistDates,
    rows,
    columns,
    cardColor,
    mainBoxHeight,
    chartRef,
}) => {

    return (
        <div style={{
            height: mainBoxHeight,
            backgroundColor: cardColor,
            marginBottom: '1rem',
        }}>
            <div style={{height: '50vh', marginBottom: '1rem'}}>
                <DataGrid
                rows={rows}
                columns={columns}
                hideFooter={true}
                />
            </div>
            <div>
            <div style={{background: 'honeydew'}}>
                <LineChart
                listName={openlistName}
                tkrList={openlistSymbols}
                startDateList={openlistDates}
                chartRef={ chartRef }
                width= "100%"
                height= "35vh"
                ></LineChart>
            </div>
            </div>
        </div>
    )
};

